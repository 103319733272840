import {
  Prompt,
  OpenAIInterface,
  OpenAIConfig,  
  LoggerInterface,
  OpenAIResult
} from '@cheaseed/node-utils';
import { getAzureOpenAIServer, MAX_OPENAI_RETRIES, OPENAI_40_TURBO_MODEL_NAME } from './constants';
import OpenAI, { AzureOpenAI } from 'openai';
import { ChatCompletionMessageParam } from 'openai/resources';

export class OpenAICompletions implements OpenAIInterface {

  openai: OpenAI
  logger: LoggerInterface

  constructor(config: OpenAIConfig) {
    const server = getAzureOpenAIServer(config.env)
    this.openai = new AzureOpenAI({
        endpoint: server.endpoint,
        deployment: config.model,
        apiVersion: "2024-07-01-preview",
        apiKey: server.key,
        maxRetries: MAX_OPENAI_RETRIES,
        // retryDelayInMs: 100,
    })
    this.logger = config.logger || console
    this.logger.log(`Using Azure OpenAI server: ${JSON.stringify(server)}`)
  }

  async logChatQuery(
    config: OpenAIConfig,
    prompt: string,
    prompts: Prompt[],
    expectsJson: boolean) {

    // Handle system prompts
    const model = config.model
    const msgs = prompts.map(p => {
      const req = { role: p.system ? 'system' : 'user', content: p.prompt }
      const resp = p.system ? null : { role: p.role, content: this.generateAsString(p.response) }
      return resp ? [req, resp] : [req]
    }).flat()
    const messages = [...msgs, { role: 'user', content: prompt }] as ChatCompletionMessageParam[]
    this.logger.log(JSON.stringify({ messageLength: messages.length, model }))
    const startmsecs = Date.now()
    const options: any = {
      temperature: config.temperature,
      top_p: config.topP
    }
    if (expectsJson && model === OPENAI_40_TURBO_MODEL_NAME) // only works with turbo
      options.responseFormat = { type: "json_object" }
    if (!config.isAzure)
      options.model = model // applicable if using OpenAI; ignored for AzureOpenAI 

    const res = await this.openai.chat.completions.create({
      model,
      messages,
      ...options
    })

    const elapsedMsec = Date.now() - startmsecs
    const response = res as OpenAI.Chat.Completions.ChatCompletion
    this.logger.log(`response took ${elapsedMsec} msecs, response:`, response)
    return {
      message: response.choices[0].message,
      finish_reason: response.choices[0].finish_reason,
      input_usage: response.usage?.prompt_tokens,
      output_usage: response.usage?.completion_tokens,
      usage: response.usage?.total_tokens,
      elapsedMsec,
      temperature: config.temperature,
      top_p: config.topP,
      model
    } as OpenAIResult

  }
  protected generateAsString(val: any) {
    return typeof val === 'string' ? val : JSON.stringify(val)
  }
}
