import OpenAI, { AzureOpenAI } from 'openai';
import { MAX_OPENAI_RETRIES, OPENAI_ASSISTANT_KEY, getAzureOpenAIServer } from './constants';
import { LoggerInterface } from './logger-interface';
import { OpenAIInterface, OpenAIConfig, OpenAIResult } from './openai-interface';
import { Prompt } from './prompts-base';


export class OpenAIAssistant implements OpenAIInterface {
  
  openai: OpenAI
  assistant: OpenAI.Beta.Assistants.Assistant | undefined
  openaiThread: OpenAI.Beta.Threads.Thread | undefined
  environment: any;
  logger?: LoggerInterface

  constructor(config: OpenAIConfig) {
    const server = getAzureOpenAIServer(config.env)
    this.openai = new AzureOpenAI({
        endpoint: server.endpoint,
        deployment: config.model,
        apiVersion: "2024-07-01-preview",
        apiKey: server.key,
        maxRetries: MAX_OPENAI_RETRIES,
        // retryDelayInMs: 100,
    })
    this.logger = config.logger || console
    this.logger.log(`Using Azure OpenAI server: ${JSON.stringify(server)}`)
  }

  async getAssistant() {
    if (!this.assistant) {
      try {
        const asst = (await this.openai.beta.assistants.retrieve(OPENAI_ASSISTANT_KEY).withResponse())
        //TODO
        //this.log('Retrieve assistant response', asst)
        this.logger.log('Retrieve assistant response', asst)
       this.assistant = asst.data
      }
      catch (e) {
        //TODO
        //this.error('Error creating assistant', e)
        console.error('Error creating assistant', e)
      }
    }
    return this.assistant;
  }
  async getThread(): Promise<OpenAI.Beta.Threads.Thread> {
    if (!this.openaiThread) {
      const res = await this.openai.beta.threads.create().withResponse()
      this.openaiThread = res.data
      //TODO
      //this.log('OpenAI Assistant Thread created with id:', JSON.stringify(this.openaiThread));
      this.logger.log('OpenAI Assistant Thread created with id:', JSON.stringify(this.openaiThread));
    }
    return this.openaiThread
  }

  async waitForRunCompletion(
    runId: string,
    threadId: string,
    interval: number = 1000,
    timeout: number = 540000
  ): Promise<{
    runResult: OpenAI.Beta.Threads.Runs.Run;
    messages: OpenAI.Beta.Threads.Messages.MessagesPage;
  }> {
    let timeElapsed = 0;

    return new Promise(async (resolve, reject) => {
      while (timeElapsed < timeout) {
        const res = await this.openai.beta.threads.runs.retrieve(threadId, runId).withResponse();
        const run = res.data
        if (run.status === 'completed') {
          const resp = await this.openai.beta.threads.runs.retrieve(threadId, runId).withResponse();
          const result = resp.data
          const messagesFromThread =
            (await this.openai.beta.threads.messages.list(threadId).withResponse()).data;
          resolve({ runResult: result, messages: messagesFromThread });
          return;
        } else if (
          run.status === 'failed' ||
          run.status === 'cancelled' ||
          run.status === 'expired'
        ) {
          reject(new Error(`Run ended with status: ${run.status}`));
          return;
        }
        await new Promise((resolve) => setTimeout(resolve, interval));
        timeElapsed += interval;
      }

      reject(
        new Error('Timeout: Run did not complete within the specified time.')
      );
    });
  }
  async createUserMessage(threadId: string, prompt: string) {
    const res = await this.openai.beta.threads.messages.create(threadId, { role: 'user', content: prompt }).withResponse();
    this.logger.log(`MESSAGE CREATE RESPONSE`, res.data)
  }

  async logChatQuery(
    config: OpenAIConfig,
    prompt: string,
    prompts: Prompt[],
    expectsJson: boolean) {

    // Note that Assistant API 1106 does not support json mode
    // One approach is to take the assistant output and pass it through the completion API
    //  with json mode enabled. It’s a little clunky but it works until its supported directly.


    this.logger.log('In logchat query')
    const asst = await this.getAssistant()
    this.logger.log('In logchat query after getAssistant: ', JSON.stringify(asst))

    const openAIThread = await this.getThread()
    // assume there is only one system prompt. use that
    // for the instructions on the thread run
    let instr = ''
    for (let i = 0; i < prompts?.length; i++) {
      if (prompts[i].system === true) {
        instr = prompts[i].prompt
        this.logger.log('System prompt detected; setting instructions on thread run', prompts[i])
      }
      else
        await this.createUserMessage(openAIThread.id, prompts[i].prompt)
    }
    await this.createUserMessage(openAIThread.id, prompt)
    const response = await this.openai.beta.threads.runs.create(openAIThread.id, {
      assistant_id: asst.id,
      instructions: instr
    }).withResponse();
    //TODO
    this.logger.log(`RUNS CREATE = `, response)
    const run = response.data
    this.logger.log('RUN ID', run.id)
    const res = await this.waitForRunCompletion(run.id, run.thread_id)
    // const tm = res.messages.getPaginatedItems()
    const item = res.messages.data[0].content[0]
    const text = item.type === "text" ? item.text.value : ''
    const obj: OpenAIResult = {
      message: { role: 'assistant', content: text },
      finish_reason: res.runResult.status,
      input_usage: 0,
      output_usage: 0,
      usage: 0,
      elapsedMsec: (res.runResult.completed_at || 0) - (res.runResult.started_at || 0),
      temperature: 0,
      top_p: 0,
      model: res.runResult.model
    }

    // clear the thread used as it will have messages attached to it
    if (this.openaiThread){
      await this.openai.beta.threads.del(this.openaiThread.id)
      this.openaiThread = undefined
    }
    return obj
  }

}
